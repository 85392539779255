import { useIsMobile, useIsTablet } from '../../../../../utils/responsiveUtils';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const SectionIntroduction = () => {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    return (
        <section className="section-introduction">
            <Typography variant="h1" style={{ textAlign: 'center', fontSize: '2.5rem', marginTop: `${(isMobile || isTablet) ? '128px' : '200px'}`, fontWeight: 'bold' }}>
                Welcome to Job Hunter
            </Typography>
            <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: '16px' }}>
                The fastest and easiest way to save and track your applications.
            </Typography>
            <Button variant="contained" color="primary" style={{ marginTop: '32px', marginLeft: 'auto', marginRight: 'auto', display: 'block', textTransform: 'none', fontWeight: 'bold' }}>
                Get started for free
            </Button>
        </section>
    )
}

export default SectionIntroduction;