/**
 * Check if the app is currently being hosted in localhost, this is used for debugging
 * @returns true is localhost, false otherwise
 */
export const isLocalHost = () => {
    return Boolean(window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
}

/**
 * Check if the app is currently being hosted on the beta domain
 * @returns true if the app is being hosted on the beta domain
 */
export const isBetaDeploy = () => {
    return window.location.hostname.includes('beta');
}