import protectedRoutes from './protected_routes.json';

/**
 * Check it the pathname that is provided matches with the protected routes
 * @param {String} pathname - pathname to check against protected routes.
 * @returns true if the pathname matches with the protected routes
 */
const checkPathnameAgainstProtectedRoutes = (pathname) => {
    return protectedRoutes.findIndex((route) => route.startsWith(pathname));
};

/**
 * Check if the user is authenticated to view the page
 * @param {Object} userState - user state object
 * @param {String} path - pathname to check against protected routes.
 * @returns true if the user is authenticated to view the page, false otherwise
 */
export const confirmAuthenticatedToView = (userState, path) => {
    // If the user is not authenticated, check if the path is protected
    if (!userState.authenticated) {
        // If the path is protected, return false, that the user is not authenticated to view this content
        if (checkPathnameAgainstProtectedRoutes(path) !== -1) {
            return false;
        }
    }
    return true;
}