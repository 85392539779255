import './App.min.css';

import { useState, lazy } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { firebaseConfig } from './config/FirebaseConfig';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useTheme } from './config/Theme';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import config from './config/config.json'
import Add from './components/pages/add/Add';
import Edit from './components/pages/edit/Edit';
import Home from './components/pages/home/Home';
import Layout from './components/pages/Layout';
import View from './components/pages/view/View';
const Account = lazy(() => import('./components/pages/account/Account'));
const Analytics = lazy(() => import('./components/pages/analytics/Analytics'));
const Dashboard = lazy(() => import('./components/pages/dashboard/Dashboard'));
const Export = lazy(() => import('./components/pages/export/Export'));
const Login = lazy(() => import('./components/pages/login/Login'));
const Resources = lazy(() => import('./components/pages/resources/Resources'));
const Register = lazy(() => import('./components/pages/register/Register'));
const Search = lazy(() => import('./components/pages/search/Search'));
const Shortcuts = lazy(() => import('./components/pages/shortcuts/Shortcuts'));
const Privacy = lazy(() => import('./components/pages/privacy/Privacy'));
const Help = lazy(() => import('./components/pages/help/Help'));
const Pricing = lazy(() => import('./components/pages/pricing/Pricing'));

function App() {
  // Initialise Firebase and get current signed in
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  // eslint-disable-next-line
  const database = getDatabase(app);

  const [userState, setUserState] = useState({
    loading: true,
    authenticated: false
  });

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserState({
        loading: false,
        authenticated: true
      })
    } else {
      setUserState({
        loading: false,
        authenticated: false
      })
    }
  })

  return (
    <Router>
      <ThemeProvider theme={useTheme()}>
        <SnackbarProvider maxSnack={3} preventDuplicate>
          <div data-testid="app" className="App">
            <Routes>
              <Route path="/" element={<Layout userState={userState} />}>
                <Route path="/" element={<Home />} />
                {config.features.account.enabled && <Route path="/account" element={<Account />} />}
                {config.features.analytics.enabled && <Route path="/analytics" element={<Analytics />} />}
                {config.features.add.enabled && <Route path="/add" element={<Add />} />}
                <Route path="/dashboard" element={<Dashboard />} />
                {config.features.edit.enabled && <Route path="/edit" element={<Edit />} />}
                {config.features.edit.enabled && <Route path="/edit/:applicationId" element={<Edit />} />}
                {config.features.export.enabled && <Route path="/export" element={<Export />} />}
                {config.features.login.enabled && <Route path="/login" element={<Login />} />}
                {config.features.resources.enabled && <Route path="/resources" element={<Resources />} />}
                {config.features.register.enabled && <Route path="/register" element={<Register />} />}
                {config.features.search.enabled && <Route path="/search" element={<Search />} />}
                {config.features.shortcuts.enabled && <Route path="/shortcuts" element={<Shortcuts />} />}
                {config.features.view.enabled && <Route path="/view" element={<View />} />}
                {config.features.view.enabled && <Route path="/view/:applicationId" element={<View />} />}
                {config.features.privacy.enabled && <Route path="/privacy" element={<Privacy />} />}
                {config.features.pricing.enabled && <Route path="/pricing" element={<Pricing />} />}
                {config.features.help.enabled && <Route path="/help" element={<Help />} />}
              </Route>
            </Routes>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
