const generatedByApplication = () => { return "\r\n\r\n\r\nGenerated by Job Hunter" }

/**
 * Format the application data so that it can be used in the file download.
 * @param {Object} application data stored in an object
 * @returns a formatted string combining all of the application data.
 */
export const formatApplicationForDownload = (application) => {
    var format = "";

    // Add the title
    format += `Title: ${application.title}\r\n`;

    if (application.organisation) format += `Organisation: ${application.organisation}\r\n`;
    if (application.status) format += `Status: ${application.status}\r\n`;
    if (application.stage) format += `Stage: ${application.stage}\r\n`;
    if (application.location) format += `Location: ${application.location}\r\n`;
    if (application.salary) format += `Salary: ${application.salary}\r\n`;
    if (application.applicationDate) format += `Application date: ${application.applicationDate}\r\n`;
    if (application.lastUpdate) format += `Last update: ${application.lastUpdate}\r\n`;
    if (application.applicationUrl) format += `Application url: ${application.applicationUrl}\r\n`;

    if (application.notes) {
        format += "\r\n"
        format += "Notes:\r\n";
        format += application.notes;
    }

    format += generatedByApplication();

    return format;
}

/**
 * Format the user data that is stored in Firebase to allow it to be downloaded
 * @param {Objection} currentUser provided by Firebase auth
 * @returns a string containing all user data
 */
export const formatUserDataForDownload = (currentUser) => {
    var format = "";

    format += `UID: ${currentUser.uid}\r\n`;
    format += `Display name: ${currentUser.displayName}\r\n`;
    format += `Email: ${currentUser.email}\r\n`;
    format += `Photo: ${currentUser.photoUrl}\r\n`;
    format += `Email verified: ${currentUser.emailVerified}\r\n`;

    format += generatedByApplication();

    return format;
}

/**
 * Format an array of applications to a format that can be used with a CSV file
 * @param {Array} applications all applications that are currently present for the user
 * @returns 
 */
export const formatApplicationsForDownload = (applications) => {
    const headers = ["ID", "Title", "Organisation", "Status", "Stage", "Location", "Salary", "Application Date", "Last Update", "Application URL", "Notes"];
    var csvRows = [];

    // Add the headers to the file
    csvRows.push(headers.join(','));

    for (var i = 0; i < applications.length; i++) {
        var value = "";
        value += (applications[i].id ? `\"${applications[i].id}\"` : " ") + ",";
        value += (applications[i].title ? `\"${applications[i].title}\"` : " ") + ",";
        value += (applications[i].organisation ? `\"${applications[i].organisation}\"` : " ") + ",";
        value += (applications[i].stage ? `\"${applications[i].stage}\"` : " ") + ",";
        value += (applications[i].status ? `\"${applications[i].status}\"` : " ") + ",";
        value += (applications[i].location ? `\"${applications[i].location}\"` : " ") + ",";
        value += (applications[i].salary ? `\"${applications[i].salary}\"` : " ") + ",";
        value += (applications[i].applicationDate ? `\"${applications[i].applicationDate}\"` : " ") + ",";
        value += (applications[i].lastUpdate ? `\"${applications[i].lastUpdate}\"` : " ") + ",";
        value += (applications[i].applicationUrl ? `\"${applications[i].applicationUrl}\"` : " ") + ",";
        value += (applications[i].notes ? `\"${applications[i].notes}\"` : " ") + ",";

        csvRows.push(value);
    }

    return csvRows.join('\n');
}

/**
 * Compile the content into a txt file and download it onto the users local system
 * @param {String} fileName name of the downloaded file
 * @param {String} fileContent string to input into the file
 */
export const createAndDownloadTxtFile = (fileName, fileContent) => {
    const element = document.createElement("a");
    const file = new Blob([fileContent], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.txt`;
    document.body.appendChild(element);
    element.click();
}

/**
 * Compile the content into a txt file and download it onto the users local system
 * @param {String} fileName name of the downloaded file
 * @param {String} fileContent string to input into the file 
 */
export const createAndDownloadCSVFile = (fileName, fileContent) => {
    const element = document.createElement("a");
    const file = new Blob([fileContent], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.csv`;
    document.body.appendChild(element);
    element.click();
}