export const INITIAL_STATE = {
    title: null,
    organisation: null,
    salary: null,
    location: null,
    applicationDate: null,
    lastUpdate: null,
    applicationUrl: null,
    stage: "Applied",
    notes: null
}

export const applicationReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_TITLE":
            return {
                ...state,
                title: action.payload
            }
        case "UPDATE_ORGANISATION":
            return {
                ...state,
                organisation: action.payload
            }
        case "UPDATE_SALARY":
            return {
                ...state,
                salary: action.payload
            }
        case "UPDATE_LOCATION":
            return {
                ...state,
                location: action.payload
            }
        case "UPDATE_APPLICATION_DATE":
            return {
                ...state,
                applicationDate: action.payload
            }
        case "UPDATE_LAST_UPDATE":
            return {
                ...state,
                lastUpdate: action.payload
            }
        case "UPDATE_APPLICATION_URL":
            return {
                ...state,
                applicationUrl: action.payload
            }
        case "UPDATE_STAGE":
            return {
                ...state,
                stage: action.payload
            }
        case "UPDATE_NOTES":
            return {
                ...state,
                notes: action.payload
            }
        case "UPDATE_APPLICATION":
            return action.payload
        case "CLEAR":
            return INITIAL_STATE;
        default:
            return state;
    }
}