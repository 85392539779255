import { useState, useEffect, Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useOnlineStatus } from '../../utils/hooks/useOnlineStatus';
import { confirmAuthenticatedToView } from '../../utils/routes/protectedRoutes';
import { print } from '../../utils/consoleUtils';

import Loading from './states/Loading';
import Header from '../common/Header';
import Offline from './offline/Offline';
import NotAuthenticated from './states/NotAuthenticated';

const Layout = ({ userState }) => {
    const isOnline = useOnlineStatus();
    const location = useLocation().pathname;
    const [isAuthenticatedToView, setIsAuthenticatedToView] = useState(false);

    useEffect(() => {
        setIsAuthenticatedToView(confirmAuthenticatedToView(location, userState));

        if (!isAuthenticatedToView) {
            print("User is not authenticated to view this page", "warn");
        };
    }, [location, userState]);

    return (
        <div data-testid="Layout" >
            <Header authenticationLoading={userState.loading} />

            {(isOnline && !userState.loading) &&
                <Suspense fallback={<Loading />}>
                    {isAuthenticatedToView
                        ? <Outlet />
                        : <NotAuthenticated />
                    }
                </Suspense>
            }

            {(isOnline && userState.loading) &&
                <Loading />
            }

            {!isOnline &&
                <Offline />
            }
        </div>
    )
}

export default Layout;