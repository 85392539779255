import { useEffect, useReducer, useState, lazy } from "react";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
// import { appStages } from '../../../data/appStages';
import { useNavigate } from 'react-router-dom';
import { applicationReducer, INITIAL_STATE } from '../../common/reducers/applicationReducer';
import { useSnackbar } from 'notistack';
import { formatDate } from "../../../utils/dateUtils";

import appStages from '../../../data/stages/app_stages.json';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CustomHelmet from "../../common/seo/CustomHelmet";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MarkdownGuideDialog from "../../common/dialogs/markdownguide/MarkdownGuideDialog";
import MarkdownPreviewDialog from '../../common/dialogs/MarkdownPreviewDialog';
import MenuItem from '@mui/material/MenuItem';
import SectionHeader from "../../common/components/SectionHeader";
import TextField from '@mui/material/TextField';

const Edit = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { applicationId } = useParams();
    const db = getDatabase();
    const { enqueueSnackbar } = useSnackbar();
    const today = Date.now();

    const application = {
        title: null,
        organisation: null,
        location: null,
        salary: null,
        applicationDate: null,
        stage: null,
        lastUpdate: null,
        notes: null,
        applicationUrl: null
    };

    const [showMarkdownDialog, setShowMarkdownDialog] = useState(false);
    const [showMarkdownPreviewDialog, setShowMarkdownPreviewDialog] = useState(false);
    const [disableFields, setDisableFields] = useState(false);
    var dataLoaded = false;

    const [updatedApplication, dispatch] = useReducer(applicationReducer, INITIAL_STATE);

    const path = `users/${auth.currentUser?.uid}/applications/${applicationId}`

    const dbRef = ref(db, path);
    onValue(dbRef, (snapshot) => {
        if (snapshot.exists) {
            const snapData = snapshot.val();
            application.title = snapData.title ? snapData.title : null;
            application.organisation = snapData.organisation ? snapData.organisation : null;
            application.location = snapData.location ? snapData.location : null;
            application.salary = snapData.salary ? snapData.salary : null;
            application.applicationDate = snapData.applicationDate ? snapData.applicationDate : null;
            application.stage = snapData.stage ? snapData.stage : null;
            application.lastUpdate = snapData.lastUpdate ? snapData.lastUpdate : null;
            application.notes = snapData.notes ? snapData.notes : null;
            application.applicationUrl = snapData.applicationUrl ? snapData.applicationUrl : null;
            dataLoaded = true;
        }
    });

    const updateApplicationInFirebase = () => {
        setDisableFields(true);

        if (!application.title || application.title === "") {
            enqueueSnackbar("Please include a title", { variant: 'error' });
            setDisableFields(false);
            return;
        }

        enqueueSnackbar("Updating...", { variant: 'info' })

        try {
            set(dbRef, { ...updatedApplication })
                .then(() => {
                    enqueueSnackbar("Updated", { variant: 'success' })
                    navigate('/dashboard');
                })
                .catch((error) => {
                    enqueueSnackbar("Something went wrong, please try again", { variant: 'error' });
                    console.debug("Error updating application:", error);
                    setDisableFields(false)
                });
        } catch (error) {
            enqueueSnackbar("Something went wrong, please try again", { variant: 'error' });
            console.debug("Error updating application:", error);
            setDisableFields(false);
        }
    }

    useEffect(() => {
        dispatch({ type: "UPDATE_APPLICATION", payload: { ...application } })
        dispatch({ type: "UPDATE_LAST_UPDATE", payload: today });
    }, [dataLoaded])

    return (
        <div data-testid="EditPage" className="edit-page app-section">
            <CustomHelmet title={application.title ? application.title : "Editing Application"} />
            <SectionHeader title={application.title ? application.title : "Editing Application"} />

            <div>
                <Paper style={{ padding: '16px', marginTop: '64px' }} elevation={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                autoFocus
                                placeholder="Title"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.title}
                                required
                                disabled={disableFields}
                                onChange={event => dispatch({ type: "UPDATE_TITLE", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Organisation"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.organisation}
                                disabled={disableFields}
                                onChange={event => dispatch({ type: "UPDATE_ORGANISATION", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Salary"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.salary}
                                disabled={disableFields}
                                onChange={event => dispatch({ type: "UPDATE_SALARY", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Location"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.location}
                                disabled={disableFields}
                                onChange={event => dispatch({ type: "UPDATE_LOCATION", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Application Date"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.applicationDate ? formatDate(application.applicationDate) : application.applicationDate}
                                disabled={disableFields}
                                onChange={event => dispatch({ type: "UPDATE_APPLICATION_DATE", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Updated"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                defaultValue={application.lastUpdate ? formatDate(application.lastUpdate) : application.lastUpdate}
                                disabled={disableFields}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Application URL"
                                fullWidth
                                autoComplete='off'
                                type="url"
                                defaultValue={application.applicationUrl}
                                disabled={disableFields}
                                onChange={event => dispatch({ type: "UPDATE_APPLICATION_URL", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Current stage"
                                fullWidth
                                select
                                defaultValue={application.stage}
                                disabled={disableFields}
                                onChange={event => dispatch({ type: "UPDATE_STAGE", payload: event.target.value })}>
                                {appStages.map((stage, index) => {
                                    return (
                                        <MenuItem key={index} value={stage.title}>
                                            {stage.title}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                placeholder="Notes"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                multiline
                                minRows={6}
                                defaultValue={application.notes}
                                disabled={disableFields}
                                onChange={event => dispatch({ type: "UPDATE_NOTES", payload: event.target.value })} />
                            <ButtonGroup variant="outlined" size="small" style={{ marginTop: '4px' }} aria-label="notes markdown controls">
                                <Button onClick={() => setShowMarkdownDialog(true)}>Markdown Guide</Button>
                                <Button onClick={() => setShowMarkdownPreviewDialog(true)} disabled={!application.notes}>Preview Note</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Paper>

                {showMarkdownDialog && <MarkdownGuideDialog onClose={() => setShowMarkdownDialog(false)} />}
                {showMarkdownPreviewDialog && <MarkdownPreviewDialog onClose={() => setShowMarkdownPreviewDialog(false)} markdown={updatedApplication.notes} />}

                <div style={{ textAlign: 'center' }}>
                    <Button
                        style={{ marginTop: '32px', marginRight: 'auto', marginLeft: 'auto' }}
                        variant="contained"
                        onClick={updateApplicationInFirebase}
                        disabled={disableFields}>
                        Save Application
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Edit;