import { appStages } from "../data/appStages";
import { daysBetween } from "./dateUtils";

export const getAppStageChipColor = (stage) => {
    if (stage === "To Apply" || stage === "Applied" || stage === "Screening" || stage === "Interviewing" || stage === "Offer Made" || stage === "Accepted") return "success";

    if (stage === "Awaiting Decision" || stage === "Screening") return "warning";

    if (stage === "Declined" || stage === "Rejected" || stage === "No Response") return "error";

    return null;
}

export const getApplicationStatus = (stage) => {
    if (stage === "To Apply" || stage === "Applied" || stage === "Screening" || stage === "Interviewing" || stage === "Offer Made" || stage === "Accepted" || stage === "Awaiting Decision" || stage === "Screening") return "active";

    if (stage === "Declined" || stage === "Rejected" || stage === "No Response") return "inactive";

    return "active";
}

/**
 * Analyse applications to find the number of applications within each stage
 * @param {Array} applications to analyse
 */
export const analyseApplicationsByStages = (applications) => {
    const results = [];

    appStages.map((stage) => {
        var numOfApps = applications.filter((app) => app.stage === stage).length;
        results.push(numOfApps);
    })

    return results;
}

/**
 * Create a promise to filter an array and return the results to `filterArray`
 * @param {Array} array 
 * @param {Function} filterQuery 
 * @returns results, or an error
 */
const filter = (array, filterQuery) => {
    return new Promise((resolve, reject) => {
        if (!array || !filterQuery) return reject("No array or filter query");

        resolve(array.filter(filterQuery));
    });
}

/**
 * Allows for the filtering of an array, this is mainly used for the search view of the dashboard
 * @param {Array} array 
 * @param {Function} filterQuery 
 * @returns results
 */
export const filterArray = async (array, filterQuery) => {
    const results = await filter(array, filterQuery);
    return results;
}

/**
 * Check if the application is aged past 30 days
 * @param {EpochTimeStamp} date - date to check
 * @returns true if the application is aged, false otherwise
 */
export const isApplicationIsAged = (date) => {
    const days = daysBetween(date);

    if (days >= 30) return true;

    return false;
}

/**
 * Check if the application has changes, used to determine if the application should be saved or confirmed with the user.
 * This can be used for both new applications and existing applications
 * @param {Object} original - original application
 * @param {Object} updated - updated application including new changes, optional
 * @returns true if the application has changes, false otherwise
 */
export const applicationHasChanges = (original, updated = null) => {
    // If there is an updated application, check if any of the fields have changed
    if (updated) {
        if (original.title !== updated.title) return true;
        if (original.organisation !== updated.organisation) return true;
        if (original.salary !== updated.salary) return true;
        if (original.location !== updated.location) return true;
        if (original.applicationDate !== updated.applicationDate) return true;
        if (original.applicationUrl !== updated.applicationUrl) return true;
        if (original.stage !== updated.stage) return true;
        if (original.notes !== updated.notes) return true;

        return false;
    }

    if (original.title) return true;
    if (original.organisation) return true;
    if (original.salary) return true;
    if (original.location) return true;
    if (original.applicationDate) return true;
    if (original.applicationUrl) return true;
    if (original.stage) return true;
    if (original.notes) return true;

    return false;
}