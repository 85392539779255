import { useState } from 'react';
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getAuth } from "firebase/auth";
import { useParams } from "react-router-dom";
import { getAppStageChipColor } from "../../../utils/applicationUtils";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from '../../../utils/hooks/useKeyPress';
import { useSnackbar } from 'notistack';
import { formatApplicationForDownload, createAndDownloadTxtFile } from '../../../utils/filemanagement/exportFunctions';
import { formatDate } from '../../../utils/dateUtils';
import { print } from '../../../utils/consoleUtils';

import config from "../../../config/config.json";
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CustomHelmet from "../../common/seo/CustomHelmet";
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import Paper from '@mui/material/Paper';
import SectionHeader from "../../common/components/SectionHeader";
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ViewItem from "./components/ViewItem";

const View = () => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const handleCloseDeleteDialog = () => { setDeleteDialogOpen(false); }

    const auth = getAuth();
    const { applicationId } = useParams();
    const application = {
        title: null,
        organisation: null,
        location: null,
        salary: null,
        applicationDate: null,
        stage: null,
        lastUpdate: null,
        notes: null,
        applicationUrl: null
    };

    const path = `users/${auth.currentUser?.uid}/applications/${applicationId}`

    // Get the data for the appication and dislay it to the user 
    const db = getDatabase();
    const dbRef = ref(db, path);
    onValue(dbRef, (snapshot) => {
        if (snapshot.exists) {
            const snapData = snapshot.val();
            application.title = snapData.title;
            application.organisation = snapData.organisation;
            application.location = snapData.location;
            application.salary = snapData.salary;
            application.applicationDate = snapData.applicationDate;
            application.stage = snapData.stage;
            application.lastUpdate = snapData.lastUpdate;
            application.notes = snapData.notes;
            application.applicationUrl = snapData.applicationUrl;
        }
    });

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * Delete the reference for the application from Firebase and then return the user to the dashboard.
     */
    const deleteApplicationFromFirebase = () => {
        handleCloseDeleteDialog();
        set(dbRef, null)
            .then(() => {
                print("Application deleted", "info");
                enqueueSnackbar("Application deleted", { variant: 'success' });
                navigate('/dashboard')
            })
            .error((error) => {
                print("Error removing reference", "error");
                enqueueSnackbar("Something went wrong", { variant: 'error' });
            });
    }

    /** If the application has a URL to the job posting, allow the user to open this in a new tab. */
    const openListing = () => {
        if (application.applicationUrl) {
            window.open(application.applicationUrl, '_blank').focus();
        }
    }

    /** Allow a user to edit the application that they are using. */
    const editApplication = () => {
        navigate(`/edit/${applicationId}`)
    }

    /** Allow a user to download the application as a txt file */
    const downloadApplication = () => {
        enqueueSnackbar("Downloading application", { variant: 'info' })
        createAndDownloadTxtFile((application.organisation ? `${application.title} - ${application.organisation}` : application.title), formatApplicationForDownload(application))
    }

    /**
     * Handle the keyboard shortcut evenets, these are registered in the `useKeyPress` hook.
     */
    const onKeyboardShortcutPressed = (event) => {
        switch (event.key) {
            case 'o':
                openListing();
                break;
            case 'e':
                editApplication();
                break;
            case 'd':
                deleteApplicationFromFirebase();
                break;
            case 's':
                downloadApplication();
                break;
            case 'Escape':
                navigate('/dashboard');
                break;
            default:
                return;
        }
    }

    useKeyPress(['d', 'e', 'o', 's', 'Escape'], onKeyboardShortcutPressed);

    return (
        <div data-testid="ViewPage" className="view-page app-section">
            <CustomHelmet title={application.title ? application.title : "Viewing Application"} />
            <SectionHeader title={application.title ? application.title : "Viewing Application"} />

            <Dialog open={deleteDialogOpen} keepMounted onClose={handleCloseDeleteDialog} aria-describedby="delete-dialog-description">
                <DialogTitle>{"Delete application?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        {`Would you like to delete the application${application.title && " for " + application.title}? This will be permanent and will not be recoverable.`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button onClick={deleteApplicationFromFirebase}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                {application.organisation}
            </Typography>

            <div style={{ textAlign: 'center', marginTop: '16px' }}>
                <Chip color={getAppStageChipColor(application.stage)} variant="outlined" size="small" label={application.stage} />
            </div>

            <Paper style={{ padding: '16px', marginTop: '32px' }} elevation={2}>
                <Grid container spacing={2}>
                    {application.location &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Location" itemContent={application.location} />
                        </Grid>
                    }

                    {application.salary &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Salary" itemContent={application.salary} />
                        </Grid>
                    }

                    {application.applicationDate &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Application Date" itemContent={formatDate(application.applicationDate)} />
                        </Grid>
                    }

                    {application.lastUpdate &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Updated" itemContent={formatDate(application.lastUpdate)} />
                        </Grid>
                    }

                    {application.applicationUrl &&
                        <Grid item xs={12} md={6}>
                            <ViewItem itemTitle="Listing" itemContent={application.applicationUrl} wrapContent={false} itemUrl={application.applicationUrl} />
                        </Grid>
                    }
                </Grid>
            </Paper>

            {application.notes &&
                <Paper style={{ padding: '16px', marginTop: '32px' }} elevation={2}>
                    <Grid item xs={12}>
                        <ViewItem itemTitle="Notes" itemContent={application.notes} markdown showTitle={false} />
                    </Grid>
                </Paper>
            }

            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" style={{ marginTop: '32px' }}>
                {application.applicationUrl &&
                    <Tooltip title="Open Listing">
                        <IconButton href={application.applicationUrl} target="_blank" rel="noopener" aria-label="open listing">
                            <LinkIcon />
                        </IconButton>
                    </Tooltip>
                }
                {config.features.edit.enabled &&
                    <Tooltip title="Edit Application">
                        <IconButton onClick={editApplication} aria-label="edit application">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                }
                {config.features.view.download.enabled &&
                    <Tooltip title="Download Application (.txt)">
                        <IconButton onClick={downloadApplication} aria-label="download applicatino">
                            <FileDownloadIcon />
                        </IconButton>
                    </Tooltip>
                }
                {config.features.view.delete.enabled &&
                    <Tooltip title="Delete Application">
                        <IconButton onClick={() => setDeleteDialogOpen(true)} aria-label="delete application">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                }
            </Stack>
        </div>
    )
}

export default View;