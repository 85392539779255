import { Helmet } from "react-helmet";

const CustomHelmet = ({ title }) => {
    return (
        <Helmet>
            <title>{title ? `${title} | Job Hunter` : "Job Hunter"}</title>
        </Helmet>
    )
}

export default CustomHelmet;