import { hyphenateString } from '../../../../utils/stringUtils';

import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown';

const ViewItem = ({ itemTitle, itemContent, itemUrl, wrapContent = true, markdown = false, showTitle = true }) => {
    const handleClick = (e) => {
        e.preventDefault();
        if (itemUrl) {
            window.open(itemUrl, '_blank').focus();
        }
    }

    return (
        <div data-testid="ViewItemComponent">
            {showTitle &&
                <Typography data-testid="ViewItemTitle" variant="caption">
                    {itemTitle}
                </Typography>
            }
            <Typography
                data-testid="ViewItemContent"
                className={`view-item-content view-item-content-${hyphenateString(itemTitle)} ${wrapContent ? '' : 'no-wrap-content'}`}
                variant="body1"
                onClick={handleClick}
                style={{
                    cursor: itemUrl ? 'pointer' : 'default'
                }}>
                {markdown
                    ? <ReactMarkdown data-testid="ViewItemMarkdown">{itemContent}</ReactMarkdown>
                    : itemContent
                }
            </Typography>
        </div>
    )
}

export default ViewItem;