import { isLocalHost } from "./utils";

/**
 * Print a message to the console if the app is running in localhost, this is used for debugging
 * @param {String} message - The message to print
 * @param {String} type - The type of message to print, can be error, warn, info or debug. This defaults to `log()`.
 */
export const print = (message, type, errorMessage = "") => {
    if (!isLocalHost())
        return;

    switch (type) {
        case "error":
            console.error("[Job Hunter]", message, errorMessage);
            break;
        case "warn":
            console.warn("[Job Hunter]", message, errorMessage);
            break;
        case "info":
            console.info("[Job Hunter]", message, errorMessage);
            break;
        case "debug":
            console.debug("[Job Hunter]", message, errorMessage);
            break;
        default:
            console.log("[Job Hunter]", message, errorMessage);
            break;
    }
}

/**
 * Print an array to the console if the app is running in localhost, this is used for debugging
 * @param {Array} array - The array to print
 */
export const printArray = (array) => {
    if (!isLocalHost())
        return;

    console.table(array);
}