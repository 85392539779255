import CircularProgress from '@mui/material/CircularProgress'

const Loading = () => {
    return (
        <div data-testid="LoadingPage" className="loading-page app-section">
            <div style={{ marginTop: '128px', textAlign: 'center' }}>
                <CircularProgress />
            </div>
        </div>
    )
}

export default Loading;