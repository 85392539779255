import { useEffect, useReducer, useState, lazy } from 'react';
// import { appStages } from '../../../data/appStages';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, set, push } from 'firebase/database';
import { applicationReducer, INITIAL_STATE } from '../../common/reducers/applicationReducer';
import { useSnackbar } from 'notistack';
import { formatDate } from '../../../utils/dateUtils';

import appStages from '../../../data/stages/app_stages.json';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import CustomHelmet from "../../common/seo/CustomHelmet";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import SectionHeader from "../../common/components/SectionHeader";
import MenuItem from '@mui/material/MenuItem';
import MarkdownPreviewDialog from '../../common/dialogs/MarkdownPreviewDialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
const MarkdownGuideDialog = lazy(() => import('../../common/dialogs/markdownguide/MarkdownGuideDialog'));

const Add = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const { enqueueSnackbar } = useSnackbar();
    const today = Date.now();

    const [disableFormFields, setDisableFormFields] = useState(false);
    const [showMarkdownDialog, setShowMarkdownDialog] = useState(false);
    const [showMarkdownPreviewDialog, setShowMarkdownPreviewDialog] = useState(false);
    const [unsavedChangesDialogOpen, setUnsaveChangesDialogOpen] = useState(false);
    const [application, dispatch] = useReducer(applicationReducer, INITIAL_STATE);

    /**
     * Save the application to Firebase, this will perform validation on the data before saving
     * @param {Boolean} addMore - Whether to add another application or not
     */
    const saveApplicationToFirebase = (addMore = false) => {
        const database = getDatabase();

        // Disable form fields to ensure that there is no editing while the data is saving.
        setDisableFormFields(true);

        const applicationListRef = ref(database, `/users/${auth.currentUser?.uid}/applications`);
        const newApplicationRef = push(applicationListRef);

        // Ensure that the application has a title
        if (!application.title || application.title === "") {
            enqueueSnackbar("Please include a title", { variant: 'error' });
            setDisableFormFields(false);
            return;
        }

        enqueueSnackbar("Saving...", { variant: 'info' });

        // Add the data to the database
        set(newApplicationRef, { ...application })
            .then(() => {
                enqueueSnackbar("Saved", { variant: 'success' });

                if (!addMore) navigate('/dashboard');

                if (addMore) {
                    document.getElementById('add-application-form').reset();
                    setDisableFormFields(false);
                }
            })
            .catch((error) => {
                enqueueSnackbar("Something went wrong. Please try again.", { variant: 'error' })
                console.debug("Error when saving application: ", error);
                setDisableFormFields(false);
            })
    }

    /**
     * Update the last update date when the page loads to the current date
     */
    useEffect(() => {
        document.getElementById("add-application-form-last-update").value = formatDate(today);
        dispatch({ type: "UPDATE_LAST_UPDATE", payload: today });
    });

    return (
        <div data-testid="AddPage" className="add-page app-section">
            <CustomHelmet title="Add Application" />
            <SectionHeader title="Add Application" />

            {/** Unsaved Changes Dialog */}
            <Dialog open={unsavedChangesDialogOpen} onClose={() => setUnsaveChangesDialogOpen(false)}>
                <DialogTitle>Unsaved Changes</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You currently have unsaved changes that will be lost if you leave this page. Would you like to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUnsaveChangesDialogOpen(false)}>
                        Delete
                    </Button>
                    <Button onClick={() => {
                        setUnsaveChangesDialogOpen(false);
                        saveApplicationToFirebase(false);
                    }}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/** Add application form */}
            <form id="add-application-form">
                <Paper style={{ padding: '16px', marginTop: '64px' }} elevation={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Title*"
                                fullWidth
                                autoComplete='on'
                                type="text"
                                required
                                disabled={disableFormFields}
                                onChange={event => dispatch({ type: "UPDATE_TITLE", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Organisation"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                disabled={disableFormFields}
                                onChange={event => dispatch({ type: "UPDATE_ORGANISATION", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Salary"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                disabled={disableFormFields}
                                onChange={event => dispatch({ type: "UPDATE_SALARY", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Location"
                                fullWidth
                                autoComplete='on'
                                type="text"
                                disabled={disableFormFields}
                                onChange={event => dispatch({ type: "UPDATE_LOCATION", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="add-application-form-application-date"
                                placeholder="Application Date"
                                fullWidth
                                autoComplete='off'
                                type="date"
                                disabled={disableFormFields}
                                onChange={event => dispatch({ type: "UPDATE_APPLICATION_DATE", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="add-application-form-last-update"
                                placeholder="Updated"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                disabled={disableFormFields}
                                InputProps={{
                                    readOnly: true
                                }} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Application URL"
                                fullWidth
                                autoComplete='off'
                                type="url"
                                disabled={disableFormFields}
                                onChange={event => dispatch({ type: "UPDATE_APPLICATION_URL", payload: event.target.value })} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                placeholder="Current stage"
                                fullWidth
                                select
                                disabled={disableFormFields}
                                defaultValue={appStages[1].id}
                                onChange={event => dispatch({ type: "UPDATE_STAGE", payload: event.target.value })}>
                                {appStages.map((stage, index) => {
                                    return (
                                        <MenuItem key={index} value={stage.id}>
                                            {stage.title}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                placeholder="Notes"
                                fullWidth
                                autoComplete='off'
                                type="text"
                                multiline
                                minRows={6}
                                style={{ whiteSpace: 'pre-wrap' }}
                                disabled={disableFormFields}
                                onChange={event => dispatch({ type: "UPDATE_NOTES", payload: event.target.value })} />
                            <ButtonGroup variant="outlined" size="small" style={{ marginTop: '4px' }} aria-label="notes markdown controls">
                                <Button onClick={() => setShowMarkdownDialog(true)}>Markdown Guide</Button>
                                <Button onClick={() => setShowMarkdownPreviewDialog(true)} disabled={!application.notes}>Preview Note</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Paper>

                {showMarkdownDialog && <MarkdownGuideDialog onClose={() => setShowMarkdownDialog(false)} />}
                {showMarkdownPreviewDialog && <MarkdownPreviewDialog onClose={() => setShowMarkdownPreviewDialog(false)} markdown={application.notes} />}

                {/** Save buttons */}
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    style={{ marginTop: '32px' }}
                >
                    <Button
                        style={{ marginRight: 'auto', marginLeft: 'auto' }}
                        variant="contained"
                        onClick={() => saveApplicationToFirebase()}
                        disabled={disableFormFields}>
                        Save Application
                    </Button>
                    <Button
                        style={{ marginRight: 'auto', marginLeft: 'auto' }}
                        variant="contained"
                        onClick={() => saveApplicationToFirebase(true)}
                        disabled={disableFormFields}>
                        Save & Add New
                    </Button>
                </Stack>
            </form>
        </div >
    )
}

export default Add;