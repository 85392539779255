import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'
import { useIsMobile, useIsTablet } from '../../utils/responsiveUtils';
import { isLocalHost, isBetaDeploy } from '../../utils/utils';
import { useSnackbar } from 'notistack';

import config from '../../config/config.json';
import AddIcon from '@mui/icons-material/Add';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LoginIcon from '@mui/icons-material/Login';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const Header = ({ authenticationLoading }) => {
    const [anchorElSettings, setAnchorElSettings] = useState(null);

    const auth = getAuth();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const { enqueueSnackbar } = useSnackbar();

    const handleOpenSettingsMenu = (event) => { setAnchorElSettings(event.currentTarget); }
    const handleCloseSettingsMenu = () => { setAnchorElSettings(null); }

    /**
     * Sign the user out using Firebase Authentication.
     */
    const signUserOut = () => {
        try {
            signOut(auth)
                .then(() => {
                    enqueueSnackbar("Signed out", { variant: 'success' });
                    localStorage.remove('userId');
                    navigate('/');
                }).catch((error) => {
                    enqueueSnackbar("Something went wrong", { variant: 'error' });
                    isLocalHost() && console.error("Failed to sign user out: ", error);
                })
        } catch (error) {
            enqueueSnackbar("Something went wrong", { variant: 'error' });
            isLocalHost() && console.error("Sign out operation failed: ", error);
        }

        handleCloseSettingsMenu();
    }

    /**
     * Close the settings menu and allow the user to navigate to the page within the app.
     * @param {string} where | location to send the user
     */
    const moveUser = (where) => {
        handleCloseSettingsMenu();
        navigate(where);
    }

    return (
        <Box data-testid="Header" id="header" sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" elevation={0}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <Link to={auth.currentUser ? "/dashboard" : "/"} style={{ textDecoration: 'none' }}>
                            Job Hunter {isBetaDeploy() && <span style={{ color: '#ff9800', fontSize: '0.75rem' }}>BETA</span>}{isLocalHost() && <span style={{ color: '#ff9800', fontSize: '0.75rem' }}>DEV</span>}
                        </Link>
                    </Typography>

                    {!authenticationLoading &&
                        <div>
                            {auth.currentUser &&
                                <Tooltip title="Dashboard">
                                    <IconButton
                                        className="header-button header-button-dashboard"
                                        size="medium"
                                        aria-label="go to dashboard"
                                        onClick={() => moveUser('/dashboard')}
                                        color="inherit"
                                    >
                                        <HomeOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            {(auth.currentUser && config.features.add.enabled) &&
                                <Tooltip title="Add Application">
                                    <IconButton
                                        className='header-button header-button-add'
                                        size="medium"
                                        aria-label="add new application"
                                        onClick={() => moveUser('/add')}
                                        color="inherit"
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            }

                            {(auth.currentUser && config.features.search.enabled) &&
                                <Tooltip title="Search">
                                    <IconButton
                                        className='header-button header-button-search'
                                        size="medium"
                                        aria-label="search applications"
                                        onClick={() => moveUser('/search')}
                                        color="inherit"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                            <IconButton
                                size="large"
                                aria-label="account avatar and menu"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenSettingsMenu}
                                color="inherit"
                            >
                                {auth.currentUser && <Avatar alt={auth.currentUser?.displayName} src="../../assets/broken_image.jpg" className="header-avatar" />}
                                {(!auth.currentUser && config.features.login.enabled) && <LoginIcon />}
                            </IconButton>

                            {/* Menu for logged in users */}
                            {auth.currentUser &&
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElSettings}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElSettings)}
                                    onClose={handleCloseSettingsMenu}
                                >
                                    {config.features.account.enabled &&
                                        <MenuItem onClick={() => moveUser('/account')}>
                                            <Typography textAlign="center">Account</Typography>
                                        </MenuItem>
                                    }

                                    {config.features.analytics.enabled &&
                                        <MenuItem onClick={() => moveUser('/analytics')} disabled>
                                            <Typography textAlign="center">Analytics</Typography>
                                        </MenuItem>
                                    }

                                    <Divider />

                                    {config.features.help.enabled &&
                                        <MenuItem onClick={() => moveUser('/help')}>
                                            <Typography textAlign="center">Help</Typography>
                                        </MenuItem>
                                    }
                                    {config.features.resources.enabled &&
                                        <MenuItem onClick={() => moveUser('/resources')}>
                                            <Typography textAlign="center">Resources</Typography>
                                        </MenuItem>
                                    }

                                    {((!isMobile && !isTablet) && config.features.shortcuts.enabled) &&
                                        <MenuItem onClick={() => moveUser('/shortcuts')}>
                                            <Typography textAlign="center">Shortcuts</Typography>
                                        </MenuItem>
                                    }

                                    <Divider />

                                    <MenuItem onClick={() => signUserOut()}>
                                        <Typography textAlign="center">Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            }

                            {/* Menu for non-logged in users */}
                            {!auth.currentUser &&
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElSettings}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElSettings)}
                                    onClose={handleCloseSettingsMenu}
                                >
                                    {config.features.login.enabled &&
                                        <MenuItem onClick={() => moveUser('/login')}>
                                            <Typography textAlign="center">Login</Typography>
                                        </MenuItem>
                                    }

                                    {config.features.register.enabled &&
                                        <MenuItem onClick={() => moveUser('/register')}>
                                            <Typography textAlign="center">Register</Typography>
                                        </MenuItem>
                                    }

                                    <Divider />

                                    {config.features.help.enabled &&
                                        <MenuItem onClick={() => moveUser('/help')}>
                                            <Typography textAlign="center">Help</Typography>
                                        </MenuItem>
                                    }

                                    {config.features.resources.enabled &&
                                        <MenuItem onClick={() => moveUser('/resources')}>
                                            <Typography textAlign="center">Resources</Typography>
                                        </MenuItem>
                                    }
                                </Menu>
                            }
                        </div>
                    }
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Header;