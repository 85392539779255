import CustomHelmet from "../../common/seo/CustomHelmet";
import SectionHeader from "../../common/components/SectionHeader";
import Typography from '@mui/material/Typography';

const Offline = () => {
    return (
        <div data-testid="OfflinePage" className="offline-page app-section">
            <CustomHelmet title="Offline" />
            <SectionHeader title="Offline" hidden />

            <div style={{ textAlign: 'center' }}>
                <Typography variant="h3">
                    Oops! Looks like you're offline.
                </Typography>
            </div>
        </div>
    )
}

export default Offline;